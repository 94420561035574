<template>
    <el-dialog
            width="500px"
            :title="!dataForm.id ? '新增' : '修改'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
                 label-width="120px" label-position="top">
            <el-form-item label="任务名称：" prop="name">
                <el-input v-model="dataForm.name" placeholder="任务名称"></el-input>
            </el-form-item>
            <el-form-item label="巡检类别：" prop="patrolType">
                <el-select v-model="dataForm.patrolType" @change="selectPatrolType" clearable placeholder="请选择">
                    <el-option
                            v-for="item in dictData.patrolTypes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="重复周期：" prop="repeatType">
                <el-select v-model="dataForm.repeatType" clearable placeholder="请选择" @change="repeatTypeChange">
                    <el-option
                            v-for="item in dictData.repeatTypes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="任务执行日期：" prop="handleTime" v-if="isShowHandleTime">
                <el-date-picker type="datetime" placeholder="选择日期"
                                v-model="dataForm.handleTime"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="运维单位：" prop="maintenanceUnitId">
                <el-select v-model="dataForm.maintenanceUnitId" @change="selectUnit" clearable placeholder="请选择">
                    <el-option
                            v-for="item in unitList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="运维负责人：" prop="maintenanceOwnerId">
                <el-select v-model="dataForm.maintenanceOwnerId" @change="selectMaintenanceOwner" clearable
                           placeholder="请选择">
                    <el-option
                            v-for="item in maintenanceOwnerList"
                            :key="item.userId"
                            :label="item.realName"
                            :value="item.userId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="巡检设备：" prop="patrolEquipmentId" v-if="dataForm.patrolType==1">
                <el-select v-model="dataForm.patrolEquipmentId" @change="selectEquipment" clearable placeholder="请选择">
                    <el-option
                            v-for="item in equipmentList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="巡检线路：" prop="patrolEquipmentId" v-if="dataForm.patrolType==2">
                <el-select
                        ref="gridSel"
                        v-model="dataForm.patrolEquipment"
                        style="width: 100%"
                        placeholder="请选择线路">
                    <el-tree
                            ref="gridTree"
                            :data="gridList"
                            :props="treeOrops"
                            node-key="id"
                            :expand-on-click-node="false"
                            @node-click="gridClick"
                            :highlight-current="true">
                    </el-tree>
                    <el-option style="display: none" v-model="dataForm.patrolEquipment"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="状态：" prop="status">
                <el-radio-group v-model="dataForm.status">
                    <el-radio :label="0">停用</el-radio>
                    <el-radio :label="1">启用</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>

export default {
    props: {
        dictData: null,
    },
    data() {
        return {
            treeOrops: {
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf',
            },
            visible: false,
            unitList: [],
            gridList: [],
            maintenanceOwnerList: [],
            equipmentList: [],
            isShowHandleTime: false, //是否显示执行时间
            dataForm: {
                id: 0,
                name: '',
                patrolType: '',
                repeatType: '',
                maintenanceUnit: '',
                maintenanceUnitId: '',
                maintenanceOwner: '',
                maintenanceOwnerId: '',
                patrolEquipmentId: '',
                patrolEquipment: '',
                handleTime: null,
                status: 1,
            },
            dataRule: {
                patrolType: [
                    {required: true, message: '巡检类别不能为空', trigger: 'blur'},
                ],
                repeatType: [
                    {required: true, message: '重复周期不能为空', trigger: 'blur'},
                ],
                maintenanceUnitId: [
                    {required: true, message: '运维单位不能为空', trigger: 'blur'},
                ],
                maintenanceOwnerId: [
                    {required: true, message: '运维负责人不能为空', trigger: 'blur'},
                ],
                patrolEquipmentId: [
                    {required: true, message: '巡检设备不能为空', trigger: 'blur'},
                ],
                status: [
                    {required: true, message: '状态不能为空', trigger: 'blur'},
                ],
            },
        };
    },
    methods: {
        init(row) {
            this.dataForm.id = row ? row.id : 0;
            this.visible = true;
            this.getInitData();
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                if (this.dataForm.id) {
                    this.dataForm = {...row};
                    this.$client.getUserByOrgId(row.maintenanceUnitId).then((res) => {
                        this.maintenanceOwnerList = res.data;
                    });
                    if (row.patrolType === 1) {
                        this.$client.getUsedEquipmentList({mainCategory: 'RING_MAIN_UNIT'}).then((res) => {
                            this.equipmentList = res.data;
                        });
                    }
                }
            });

            this.isShowHandleTime = row.repeatType === 'TEMP' ? true : false;
        },
        selectEquipment(val) {
            for (let item of this.equipmentList) {
                if (item.id === val) {
                    this.dataForm.patrolEquipment = item.name;
                }
            }
        },
        gridClick(data) {
            this.dataForm.patrolEquipmentId = data.id;
            this.dataForm.patrolEquipment = data.name;
            this.$refs.gridSel.blur();
        },
        selectMaintenanceOwner(val) {
            for (let item of this.maintenanceOwnerList) {
                if (item.userId === val) {
                    this.dataForm.maintenanceOwner = item.realName;
                }
            }
        },
        selectUnit(val) {
            this.dataForm.maintenanceOwner = '';
            this.dataForm.maintenanceOwnerId = '';
            for (let item of this.unitList) {
                if (item.id === val) {
                    this.dataForm.maintenanceUnit = item.name;
                }
            }
            this.$client.getUserByOrgId(val).then((res) => {
                this.maintenanceOwnerList = res.data;
            });
        },
        getInitData() {
            //约定维保组织code以WB开头
            this.$client.getOrgUnitByOrgTypeAndCode({orgType: 'department', code: 'WB'}).then((res) => {
                this.unitList = res.data;
            });
            this.$client.getPowerGridsTree().then(res => {
                this.gridList = res.data;
            });
        },
        selectPatrolType(val) {
            switch (val) {
                case 1:
                    this.dataForm.patrolEquipment = '';
                    this.dataForm.patrolEquipmentId = '';
                    this.$client.getUsedEquipmentList({mainCategory: 'RING_MAIN_UNIT'}).then((res) => {
                        this.equipmentList = res.data;
                    });
                case 2:
                    this.dataForm.repeatType = 'MONTH';
                    break;
                case 3:
                    this.dataForm.repeatType = 'DAY';
                    /* this.dataForm.patrolEquipment = '';
                    this.dataForm.patrolEquipmentId = '';
                    this.$client.getUsedEquipmentList({mainCategory: 'SUBSTATION'}).then((res) => {
                        this.equipmentList = res.data;
                    });*/
                    break;
                default:
                    break;
            }
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    //变电站暂时使用
                    if (this.dataForm.patrolType === 3) {
                        this.dataForm.patrolEquipment = '110KV汽车园变电站';
                    }
                    const submit = this.dataForm.id ? this.$client.updatePatrol : this.$client.savePatrol;
                    submit(this.dataForm).then((data) => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.visible = false;
                        this.$emit('refreshDataList', 1);
                    });
                }
            });
        },
        repeatTypeChange(val) {
            if (val === 'TEMP') {
                this.isShowHandleTime = true;
            }
        },

    }

};
</script>
