<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions" style="margin: 20px 20px 0px 20px">
            <el-button @click="addOrUpdateHandle" type="primary" style="border-radius: 4px">+ 新增</el-button>
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getPatrolList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
            >
                <template slot="append">
                    <el-table-column
                        align="center"
                        label="操作"
                        type="action"
                        width="120"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <el-tooltip content="编辑" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_edit"
                                    @click="addOrUpdateHandle(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="删除" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_delete"
                                    @click="deleteHandle(scope.row.id)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <!-- 弹窗, 新增 / 修改 -->
            <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" :dictData="{patrolTypes,repeatTypes}"></add-or-update>
        </div>
    </div>
</template>

<script>
import AddOrUpdate from './patrol-add-or-update';
import * as dayjs from 'dayjs';

export default {
    data() {
        return {
            patrolTypes: [],
            repeatTypes: [],
            addOrUpdateVisible: false,
        };
    },
    components: {
        AddOrUpdate,
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'name',
                    label: '任务名称',
                },
                {
                    prop: 'patrolType',
                    label: '巡检类别',
                    formatter: (row, column, cellValue) => {
                        for (let item of this.patrolTypes) {
                            if (item.value === cellValue) {return item.name;}
                        }
                    },
                },
                {
                    prop: 'repeatType',
                    label: '巡检周期',
                    formatter: (row, column, cellValue) => {
                        for (let item of this.repeatTypes) {
                            if (item.value === cellValue) {return item.name;}
                        }
                    },
                },
                {
                    prop: 'maintenanceUnit',
                    label: '运维单位',
                },
                {
                    prop: 'maintenanceOwner',
                    label: '负责人',
                },
                {
                    prop: 'createTime',
                    formatter: (row, column, cellValue) => {
                        if (cellValue) {
                            let time = dayjs(cellValue).format('YYYY-MM-DD HH:mm');
                            return time;
                        }
                        return '';
                    },
                    label: '创建时间',
                },
                {
                    prop: 'status',
                    label: '状态',
                    formatter: (row, column, cellValue) => {
                        return cellValue === 0 ? '停用' : '启用';
                    },
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'repeatType',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择巡检周期',
                        options: this.repeatTypes,
                        valueKey: 'value',
                        labelKey: 'name',
                    },
                    {
                        prop: 'patrolType',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择巡检类型',
                        options: this.patrolTypes,
                        valueKey: 'value',
                        labelKey: 'name',
                    },
                    {
                        prop: 'key',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入任务名称或编号',
                    },
                ],
            };
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.$client.listDictionaryChildrenArr(['patrol_type', 'repeat_type']).then((res) => {
                this.patrolTypes = res.data.patrol_type.map(item => {
                    item.value = parseInt(item.value);
                    return item;
                });
                this.repeatTypes = res.data.repeat_type;
            });
        },
        getDataList(type) {
            if (type) {
                this.$refs.searchTable.searchHandler();
            }
        },
        // 新增
        addOrUpdateHandle(row) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(row);
            });
        },
        // 删除
        deleteHandle(id) {
            if (id) {
                this.$confirm('确定进行删除操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$client.delPatrol(id).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.$refs.searchTable.searchHandler();
                    });
                });
            }
        }
    },
};
</script>
